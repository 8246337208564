<template>
  <div class="content-text">{{ content }}</div>
</template>

<script>
export default {
  name: 'TextContent',
  props: {
    content: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.content-text {
  margin-bottom: 8px;
  padding: 14px 12px;
  background-color: #f5f7fa;
  border-radius: 5px;
  color: #4a4a4a;
  line-height: 20px;
  font-size: 14px;
}
</style>
