<template>
  <!-- /labelmarketing-detail -->
  <div class="marketing-detail-page">
    <div class="marketing-detail-box-item">
      <div class="detail-box-title weight500">因为客户命中以下标签</div>
      <div class="detail-box-content">
        <div
          v-for="labelItem in labelList"
          :class="[
            'label-item',
            labelItem.tag_group_type == 'HQ' ? 'la-headquarters' : 'la-service'
          ]"
        >
          <div class="label-title">{{ labelItem.tag_group_name }}</div>
          <div class="label-value">
            <p class="txt-line-3">{{ labelItem.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="marketing-detail-box-item">
      <div class="detail-box-title weight500">所以为客户推荐发送以下内容</div>
      <div class="detail-media-box">
        <TextContent
          v-for="textItem in textList"
          :content="textItem.content"
        ></TextContent>
        <div class="content-media">
          <MediaGride
            v-if="mediaList.length"
            :detail="detail"
            :mediaList="mediaList"
            pageType="knowledge"
          ></MediaGride>
        </div>
      </div>
    </div>
    <div class="bottom-btn-box">
      <div class="send-btn" @click="onSend">
        <img
          class="send-icon"
          src="@/assets/img/suggestion/share.svg"
          alt="分享"
        />
        <div class="send-text">发送</div>
      </div>
    </div>
  </div>
</template>
<script>
import { detail } from '../tabPage/test'
import TextContent from '@/components/detail/TextContent.vue'
import MediaGride from '@/components/detail/mediaGride.vue'
import {
  getMarketingLabelDetail,
  setBackSend,
  sendMonitor
} from '../../../../service/quickSend.service'
import { sendChatMessageAllType } from '../../../../utils/nwechat'
import { formatParamsDistribution } from '@/utils/index.js'
export default {
  components: {
    TextContent,
    MediaGride
  },
  data() {
    return {
      textList: [],
      mediaList: [],
      labelDetail: {},
      sale_script_id: '',
      detail: {},
      chat_id: '',
      customer_userid: '',
      tagIdArr: [],
      share_data_id: ''
    }
  },
  mounted() {
    this.sale_script_id = (this.$route.query && this.$route.query.id) || ''
    this.customer_userid = this.$route.query.customer_userid || ''
    this.chat_id = this.$route.query.chat_id || ''
    // let str = sessionStorage.getItem('labelMarketingDetail')
    // this.labelDetail = JSON.parse(str)
    this.getDetail()
  },
  methods: {
    async getDetail() {
      let { data } = await getMarketingLabelDetail({
        sale_script_id: this.sale_script_id
      })
      // console.log(detail.data.content_arr)
      this.detail = data || {}
      this.textList = this.detail.content_arr.filter((item) => {
        return item.type == 'TEXT'
      })
      this.mediaList = this.detail.content_arr.filter((item) => {
        return item.type != 'TEXT'
      })
      this.labelList = data.tag_arr || []
      // console.log(data)
      this.labelList.forEach((tagItem) => {
        this.tagIdArr.push(tagItem.tag_id)
      })
    },

    // 发送前的埋点回调
    async beforeSend() {
      let { data, code } = await sendMonitor({
        payable_id: this.sale_script_id,
        type: 'tag_marketing',
        user_id: this.customer_userid
      })
      if (code == 200) {
        this.share_data_id = data.share_data_id
      }
      return Promise.resolve(true)
    },

    // 点击发送
    async onSend() {
      // 如果发送给个人
      if (this.customer_userid) {
        await this.beforeSend()
      }
      try {
        let mediaList = this.detail.content_arr || []
        // 根据页面类型
        for (let i = 0; i < mediaList.length; i++) {
          console.log('发送消息', i)
          let type = await this.formatType(mediaList[i].type)
          let params = await this.formatSendParams(
            type,
            mediaList[i],
            share_data_id
          )
          let { sendStatus } = await sendChatMessageAllType(type, params, false)
          // console.log('发送状态：-----------：', sendStatus)
          this.taskStatus = this.taskStatus ? this.taskStatus : sendStatus
        }

        if (this.taskStatus) {
          this.onSended()
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 发送之后进行回调
    async onSended() {
      let type = 'customer_receive_tag'
      let field = 'external_contact'
      if (this.chat_id) {
        type = 'customer_touch_tag_group'
        field = 'chat_id'
      }
      await setBackSend({
        sale_script_id: this.sale_script_id,
        event_key: type,
        field: field,
        field_value: this.customer_userid || this.chat_id,
        tag_idx: this.tagIdArr || []
      })

      // 发送完成后关闭当前窗口
      // window.location.href = 'about:blank'
      // window.close()
      wx.closeWindow()
    },

    // 判断wechat文件类型
    formatType(medisType) {
      let type = ''
      switch (medisType) {
        case 'TEXT':
          type = 'text'
          break
        case 'IMAGE':
          type = 'image'
          break
        case 'LINK':
          type = 'news'
          break
        case 'VIDEO':
          type = 'video'
          break
        case 'MINIPROGRAM':
          type = 'miniprogram'
          break
        case 'FILE':
          type = 'news'
          break
        default:
          type = 'text'
          break
      }
      // console.log(1, type)
      return Promise.resolve(type)
    },

    // 企微接口参数格式化
    async formatSendParams(type, item, share_data_id) {
      if (item.file_url && item.file_url.indexOf('http') === -1) {
        item.file_url = 'https://' + item.file_url
      }

      console.log('参数', type, item)
      let params = {}
      if (['image', 'video'].includes(type)) {
        params[type] = {
          mediaid: item.media_id
        }
      } else if (type == 'news') {
        // 普通h5
        if (item.type != 'FILE') {
          let imgUrl = item.file_url
            ? item.file_url
            : 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
          params[type] = {
            link: item.qw_link_url, //H5消息页面url 必填
            title: item.qw_link_title, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imgUrl //H5消息封面图片URL
          }
        } else {
          // 文件类型附件，当做h5发出去
          // 环境不同需要更换
          let baseURL = location.origin + '/preview-page?file='
          let imageUrl = this.getFileCover(item)
          params[type] = {
            link: baseURL + item.file_url + `&type=${item.file_suffix}`, //H5消息页面url 必填
            title: item.file_name, //H5消息标题
            desc: item.qw_link_desc || '', //H5消息摘要
            imgUrl: imageUrl //H5消息封面图片URL
          }
        }
      } else if (type == 'miniprogram') {
        let path = await formatParamsDistribution(
          item.qw_miniprogram_page,
          this.customer_userid,
          this.chat_id,
          'label'
        )
        // 如果存在埋点id加入埋点id
        if (share_data_id) path = `${path}&share_data_id=${share_data_id}`
        params[type] = {
          appid: item.qw_miniprogram_appid, //小程序的appid
          title: item.qw_miniprogram_title, //小程序消息的title
          imgUrl: item.file_url, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page: path
        }
      } else if (type == 'text' && item.content) {
        // params.content = item.content
        params[type] = {
          content: item.content
        }
      } else {
        return ''
      }
      console.log(2, params)
      return Promise.resolve(params)
    },
    // 处理小程序路径加上分配所需参数
    // formatParamsDistribution(path) {
    //   let obj = {
    //     member_id: localStorage.getItem('member_id') || '',
    //     customer_id: this.customer_userid || '',
    //     chart_id: this.chat_id || '',
    //     source: 'label'
    //   }
    //   let tempPath = path
    //   for (let key in obj) {
    //     if (obj[key]) {
    //       tempPath = `${tempPath}&${key}=` + obj[key]
    //     }
    //   }
    //   return Promise.resolve(tempPath)
    // },

    // 发送时获取图片封面(文件类型转h5)
    getFileCover(item) {
      if (['doc', 'docx'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/eb29bd4da195ca9937f6691e8c5cd92b.png'
      } else if (item.type == 'FILE' && ['pdf'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/e4624c7404b38a1a38d28d125716f3c3.png'
      } else if (['xlsx', 'xls'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/063bbd6dff45fee185467d7c12318dfe.png'
      } else if (['pptx', 'ppt'].includes(item.file_suffix)) {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/1f84978621cf8adbc47ebac8e83213dd.png'
      } else {
        return 'https://wechat-customer-1301506715.cos.ap-guangzhou.myqcloud.com/58c6206f18e0db40ee139ea2563a42b5.png'
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import url('~@/assets/style/bottom_btn.less');
.marketing-detail-page {
  .marketing-detail-box-item {
    padding: 0 15px 15px 15px;
    background-color: #fff;
    margin-top: 8px;
  }
  .detail-box-title {
    display: flex;
    align-items: center;
    line-height: 46px;
    color: #4a4a4a;
    font-size: 14px;
    border-bottom: 1px solid #f5f5f5;
    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 20px;
      background-color: #1773fa;
      margin-right: 8px;
    }
  }

  .detail-box-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 6px;
    align-items: center;

    .label-item {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      line-height: 16px;
      align-items: center;
      font-size: 12px;
      border-radius: 2px;
      max-height: 54px;
      margin-right: 8px;
      margin-top: 9px;
      height: 100%;
      position: relative;

      .label-title {
        // flex: 1;
        padding: 2px 4px;
        max-width: 128px;
        // border-radius: 2px 0px 0px 2px;
        // white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .label-value {
        flex: 1;
        color: #fff;
        padding: 2px 8px;
        max-width: 136px;
        // position: relative;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .more-label {
      color: #1773fa;
      display: flex;
      align-items: flex-end;
    }
    // 总部标签
    .la-headquarters {
      border: 1px solid #1773fa;
      .label-title {
        color: #1773fa;
        // border: 1px solid #1773fa;
      }
      .label-value {
        background-color: #1773fa;
        // border: 1px solid #1773fa;
      }
    }
    //服务标签
    .la-service {
      border: 1px solid #ff6f00;
      .label-title {
        color: #ff6f00;
        // border: 1px solid #ff6f00;
      }
      .label-value {
        background-color: #ff6f00;
        // border: 1px solid #ff6f00;
      }
    }

    // .label-item + .label-item {
    //   margin-left: 8px;
    // }
  }

  .detail-media-box {
    padding-bottom: 50px;
    padding-top: 15px;
  }
}
</style>
